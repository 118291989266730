import React from 'react';
import styles from './Datenschutz.module.css';

const Datenschutz = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Datenschutzerklärung für die Webseite Ciao Barista</h2>

      <h3>1. Verantwortlicher</h3>
      <p>
        Max Zipfel<br />
        Kreuzerweg 54, 81825 München<br />
        E-Mail: ciao@ciaobarista.com
      </p>

      <h3>2. Erhobene Daten</h3>
      <p>
        Auf dieser Webseite verwenden wir Google Analytics 4 (GA4), einen Webanalysedienst der Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland. GA4 verwendet Cookies und ähnliche Technologien, um Informationen über die Nutzung unserer Webseite zu sammeln. Die Erhebung dieser Daten erfolgt nur mit Ihrer aktiven, vorherigen Einwilligung durch Bestätigen unseres Cookie-Banners. Die von Google Analytics 4 erfassten IP-Adressen werden anonymisiert, bevor sie gespeichert werden. Es findet eine Übertragung der Daten an Server in den USA statt.
      </p>
      <p>Folgende Daten werden durch GA4 erhoben (sofern Sie der Verwendung von GA4 zugestimmt haben):</p>
      <ul>
        <li>Online-Kennungen: IP-Adresse (anonymisiert), Cookie-IDs, Geräte-IDs</li>
        <li>Nutzerinteraktionen: Seitenaufrufe, Klicks, Scrollen, Verweildauer, Formularabschickungen, Downloads, Interaktionen mit einzelnen Elementen (z.B. Videos, Buttons)</li>
        <li>Geräteinformationen: Gerätetyp, Betriebssystem, Browsertyp, Bildschirmauflösung</li>
        <li>Ungefährer Standort: Stadt, Land (basierend auf der anonymisierten IP-Adresse)</li>
        <li>Traffic-Quellen: Informationen darüber, wie Nutzer auf unsere Webseite gelangt sind (z.B. Suchmaschinen, soziale Medien, direkte Zugriffe)</li>
        <li>Demografische Daten und Interessen (sofern aktiviert und von Google verfügbar): Alter, Geschlecht, Interessen (basierend auf Google-Daten). Diese Daten sind oft nur in aggregierter Form verfügbar und unterliegen Datenschwellen.</li>
      </ul>
      <p>
        Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>
      </p>

      <h3>3. Zweck der Datenerhebung und Rechtsgrundlage</h3>
      <p>
        Die Erhebung und Verarbeitung der Daten durch Google Analytics 4 dient folgenden Zwecken:
      </p>
      <ul>
        <li>Analyse des Nutzerverhaltens: Wir analysieren, wie Nutzer unsere Webseite nutzen, um deren Inhalte und Funktionalitäten zu verbessern und an die Bedürfnisse der Nutzer anzupassen.</li>
        <li>Messung der Webseiten-Performance: Wir messen die Performance unserer Webseite, um deren Effektivität zu beurteilen und Optimierungsmaßnahmen abzuleiten.</li>
        <li>Erstellung von Nutzungsstatistiken: Wir erstellen anonymisierte oder pseudonymisierte Statistiken über die Nutzung unserer Webseite.</li>
        <li>Verbesserung des Nutzererlebnisses: Wir nutzen die gewonnenen Erkenntnisse, um das Nutzererlebnis auf unserer Webseite stetig zu verbessern.</li>
      </ul>
      <p>
        <strong>Rechtsgrundlage:</strong> Die Verarbeitung der Daten durch Google Analytics 4 erfolgt auf Grundlage Ihrer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Sie können Ihre Einwilligung jederzeit für die Zukunft über das Cookie-Banner widerrufen. Mit Google wurde ein Auftragsverarbeitungsvertrag gemäß Art. 28 DSGVO geschlossen.
      </p>

      <h3>4. Speicherdauer</h3>
      <p>
        Die durch Google Analytics 4 erhobenen Daten werden standardmäßig für einen Zeitraum von 14 Monaten gespeichert. Nach Ablauf der Speicherdauer werden die Daten automatisch gelöscht.
      </p>

      <h3>5. Weitergabe von Daten</h3>
      <p>
        Die im Rahmen von Google Analytics 4 erhobenen Daten werden an Google in den USA übertragen und dort verarbeitet. Es findet keine weitere Weitergabe an Dritte statt, außer wenn dies gesetzlich vorgeschrieben ist.
      </p>

      <h3>6. Ihre Rechte (gemäß DSGVO)</h3>
      <p>Sie haben folgende Rechte gemäß der DSGVO:</p>
      <ul>
        <li>Auskunft über gespeicherte Daten (Art. 15 DSGVO)</li>
        <li>Berichtigung falscher/unvollständiger Daten (Art. 16 DSGVO)</li>
        <li>Löschung der Daten (sofern keine gesetzlichen Aufbewahrungspflichten bestehen) (Art. 17 DSGVO)</li>
        <li>Einschränkung der Verarbeitung (unter bestimmten Voraussetzungen) (Art. 18 DSGVO)</li>
        <li>Widerruf einer erteilten Einwilligung (Art. 7 Abs. 3 DSGVO)</li>
        <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
        <li>Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde (Art. 77 DSGVO)</li>
      </ul>
      <p>
        Zur Ausübung Ihrer Rechte kontaktieren Sie uns bitte unter: ciao@ciaobarista.com. Sie haben zudem das Recht, sich bei der zuständigen Datenschutzaufsichtsbehörde zu beschweren: Bayerisches Landesamt für Datenschutzaufsicht (BayLDA), Promenade 27, 91522 Ansbach.
      </p>

      <h3>7. Änderungen der Datenschutzbestimmungen</h3>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen. Die jeweils aktuelle Version wird auf der Website veröffentlicht. Wir empfehlen Ihnen, die Datenschutzerklärung regelmäßig einzusehen.
      </p>

      <h3>8. Kontakt</h3>
      <p>
        Für Fragen oder Anliegen zum Datenschutz kontaktieren Sie uns bitte unter: <br />
        ciao@ciaobarista.com
      </p>
    </div>
  );
};

export default Datenschutz;
