import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './styles/GlobalStyles.css';
import styles from './App.module.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import UseCases from './components/UseCases';
import DownloadNow from './components/DownloadNow';
import SupportedQuestions from './components/SupportedQuestions';
import Footer from './components/Footer';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';

// Initialize Google Analytics
const initGA = () => {
  ReactGA.initialize('G-KWL91W005N');
};

const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

const handleDecline = () => {
  Cookies.remove('_ga');
  Cookies.remove('_gid');
  Cookies.remove('_gat');
};

function App() {
  useEffect(() => {
    if (getCookieConsentValue('myAwesomeCookieName') === 'true') {
      initGA();
      logPageView();
    }
  }, []);

  return (
    <Router>
      <div className={styles.outerContainer}>
        <Header />
        <div className={styles.mainContent}>
          <Routes>
            <Route path="/" element={<>
              <Hero />
              <Gallery />
              <UseCases />
              <SupportedQuestions />
              <DownloadNow />
            </>} exact />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
          </Routes>
        </div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#1CA45C", color: "#fff", fontSize: "16px", borderRadius: "5px", padding: "10px 20px" }}
          declineButtonStyle={{ color: "#fff", background: "#f44336", fontSize: "16px", borderRadius: "5px", padding: "10px 20px" }}
          enableDeclineButton
          onAccept={() => {
            initGA();
            logPageView();
          }}
          onDecline={handleDecline}
          expires={150}
        >
          Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern und das Nutzerverhalten zu analysieren. Dies umfasst Cookies von Google Analytics 4. Durch Klicken auf "Akzeptieren" stimmen Sie der Verwendung dieser Cookies und der damit verbundenen Verarbeitung Ihrer Daten gemäß Art. 6 Abs. 1 lit. a DSGVO zu. Mehr über unsere Cookie-Richtlinie erfahren Sie in unserer <Link to="/datenschutz" style={{ color: "#fff", textDecoration: "underline" }}>Datenschutzerklärung</Link>.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
